.container {
  cursor: pointer;
  width: 193px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('../../../../assets/icons/logo.png');
}

@media screen and (max-width: 468px) {
  .container {
    width: 200px;
  }
}

@media screen and (max-width: 380px) {
  .container {
    width: 170px;
  }
}