@import 'styles/layers.scss';

@layer uikit {
  .group {
    width: 100%;
  }
  
  .tab-wrapper {
    background-color: var(--black3);
    padding: 26px 20px 0;
    width: 100%;
  }
  
  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 20px;
    gap: 20px;
  }
}