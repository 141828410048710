@import 'styles/layers.scss';

@layer component {
  .wrap {
    height: 100%;
    padding-bottom: 22px;
  }

  .content {
    padding: 0 22px;
    height: 558px;
    overflow-y: auto;
  }

  .title {
    font-size: 20px;
  }

  .title-wrapper {
    margin-bottom: 30px;
  }

  .tabs-wrap {
    margin-bottom: 12px;
    position: relative;
    column-gap: 20px;
    width: 100%;
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: var(--white);
      opacity: 0.1;
      top: 32px;
    }
  }

  .notification-wrapper {
    padding: 0 22px;
    margin-bottom: 20px;
  }
}