@import 'styles/layers.scss';

@layer uikit {
  .dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    height: 37px;
    overflow: hidden;
    border-radius: 4px;
    border-color: transparent;
    background-color: var(--black4);
    color: var(--white);
  }
  
  .metaicon {
    margin-right: 6px;
  }
  
  .dropdown-menu {
    width: 157px;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .menu-item {
    cursor: pointer;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    color: var(--white);
    .disabled {
      opacity: 0.3;
    }
    &:hover {
      background: var(--black1);
    }
  }
  
  .wrap-menu-item {
    width: 100%;
  }
  
  .menu-item-text {
    padding: 10px 0;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: var(--white);
    width: 100%;
    &:hover, &:active {
      color: var(--white);
    }
  }
  
  .dropdown-menu {
    background: var(--black14);
    &:hover {
      background: var(--black14);
    }
  }  
}
