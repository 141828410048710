@import 'styles/layers.scss';

@layer component {
  .title-group {
    margin-bottom: 12px;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
  }

  .sub-text {
    font-size: 12px;
    margin-top: 4px;
    color: var(--grey7);
  }

  .text-line {
    margin: 0 0 12px 0;
  }

  .text-line-updown {
    margin: 12px 0;
  }

  .text-line-up {
    margin: 12px 0 0 0;
  }

  .link {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    &:hover, &:active {
      color: inherit;
    }
  }

  .subpoints {
    margin: 0;
    padding: 0 0 0 22px;
    list-style-type: none;
    line-height: 160%;
  }

  .subpoint {
    position: relative;
  }

  .subpoint::before {
    content: "";
    position: absolute;
    background-color: var(--white);
    width: 3px;
    height: 3px;
    border-radius: 50px;  
    top: 10px;
    left: -14px;
  }
}
